import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FuseUtilsService } from '@fuse/services/utils';
import { environment } from 'environments/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'Image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements AfterViewInit {
  @Input() src: string;
  @Input() width?: string;
  @Input() height?: string;
  @Input() class?: string;
  @Input() title?: string;
  @Input() alt?: string;
  @Input() name?: string;
  @Input() id?: string;
  @Input() caption?: string;
  @Input() href?: string;
  @Input() target?: string;
  @Input() imageLazy?: string = 'lazy';
  @Input() full = true;

  @Output() txClick = new EventEmitter<any>();
  @Output() imageLoad = new EventEmitter<any>();

  @ViewChild('thumbEl') $thumbEl: ElementRef<HTMLImageElement>;

  fuseUtilsService = inject(FuseUtilsService);

  ngAfterViewInit(): void {
    if (this.id) {
      this.$thumbEl.nativeElement.id = this.id;
    } else {
      this.$thumbEl.nativeElement.id = this.fuseUtilsService.randomId();
    }
    if (this.name) {
      this.$thumbEl.nativeElement.name = this.name;
    }
    if (this.width) {
      this.$thumbEl.nativeElement.width = parseInt(this.width);
    }
    if (this.height) {
      this.$thumbEl.nativeElement.height = parseInt(this.height);
    }
    if (this.title) {
      this.$thumbEl.nativeElement.title = this.title;
    }
    if (this.alt) {
      this.$thumbEl.nativeElement.alt = this.alt;
    }
    this.$thumbEl.nativeElement.onload = () => {
      this.imageLoad.emit({
        elem: this.$thumbEl,
        src: this.src,
        width: this.$thumbEl.nativeElement.width,
        height: this.$thumbEl.nativeElement,
        status: 'loaded',
      });
    };
  }

  get img() {
    return {
      src: this.src,
      w: this.width,
      h: this.height,
      class: this.class,
      title: this.title,
      alt: this.alt,
      caption: this.caption,
      href: this.href,
    };
  }

  get iSrc(): string {
    if (this.full) {
      return `${environment.assets}${this.src}` || '';
    }
    return `${this.src}` || '';
  }

  onClick(e: MouseEvent): void {
    // Give the user an option to open the image in a new tab
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    e.preventDefault();

    this.txClick.emit(this.img);
  }
}
