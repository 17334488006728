export const environment = {
  production: false,
  graphql: 'https://motorbest-admin.targx.com/graphql',
  api: 'https://motorbest-admin.targx.com/api',
  base: 'https://motorbest.targx.com/',
  assets: 'https://motorbest-admin.targx.com',
  solr: 'https://motorbest-solr.targx.com/api',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE: 'mtb-stg-access_token',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  KEY_LOCAL_STORAGE_COMPANY: 'mtb-stg-company',

  passphrase:
    'Obedient Cool Feel Develop Sink Motion Funny Theater Mud Committee 5',
  aeskey: 'r6slsXSet8vGiCj9yIXKviu4PqhnKctR',
};
