<Image
  [src]="product.images[0].url"
  [alt]="product.images[0].alt"
  [href]="link"
  [full]="false"
  class="h-full max-h-50 md:max-h-60"
>
</Image>
<a [routerLink]="link">
  <h4
    class="text-lg leading-3xl font-semibold text-center mb-2 mt-4 flex justify-center h-12 overflow-y-hidden font-sans"
  >
    {{ product?.year_i }}
    {{ product.name_s }}
  </h4>

  <div
    class="text-md leading-2xl font-semibold text-center mb-2 text-gray50-500"
  >
    <ng-container *ngIf="!product.price_f">
      {{ "Sob Consulta" | transloco }}
    </ng-container>
    <ng-container *ngIf="product.price_f">
      {{ product.price_f | currency: "EUR" }}
    </ng-container>
  </div>

  <div
    class="text-sm leading-base font-light text-center flex justify-center items-center"
  >
    <img src="assets/images/flags/PT.svg" alt="Location" class="w-4 h-3 mr-2" />
    Porto, Portugal
  </div>
</a>
