import { Injectable } from '@angular/core';
import { LocalesService } from '@core/transloco/locales.service';
import { ApiService } from '@targx/services/api/api.service';
import qs from 'qs';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { ProductSimple } from './product.model';

interface SolrResponse {
  facet_counts: any;
  response: {
    docs: any;
    numFound: number;
    numFoundExact: boolean;
    start: number;
  };
  responseHeader: any;
}

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  private _products: any;
  private _product: any;

  public onProductsChanged: ReplaySubject<any> = new ReplaySubject<any>(1);
  public onProducfChanged: ReplaySubject<any> = new ReplaySubject<any>(1);

  public onMarketplaceHomeChanged: ReplaySubject<any> = new ReplaySubject<any>(
    1,
  );

  public locale = { code: 'pt-PT' };
  constructor(
    private api: ApiService,
    private localeService: LocalesService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for footer
   */
  get products$(): Observable<any> {
    return this.onProductsChanged.asObservable();
  }

  /**
   * Getter for footer
   */
  get product$(): Observable<any> {
    return this.onProducfChanged.asObservable();
  }

  /**
   * Getter for footer
   */
  get mktHome$(): Observable<any> {
    return this.onMarketplaceHomeChanged.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // filters[article_categories][slug][$contains]=historia
  // {"article_categories":{"slug":{"$contains":"historia"}}}
  // sort=createdAt:desc&pagination[page]=0&pagination[pageSize]=5
  // -----------------------------------------------------------------------------------------------------
  getPage(): Observable<any> {
    // const qs = [
    //   'locale=pt-PT',
    //   'populate[0]=*',
    //   'populate[columnA][populate]=*',
    //   'populate[columnB][populate]=*',
    //   'populate[columnC][populate]=*',
    //   'populate[columnD][populate]=*',
    // ];
    this.locale = this.localeService.getActiveLocale();
    const data = {
      locale: this.locale.code,
      'populate[0]': '*',
      'populate[banner][populate]': '*',
    };
    return this.api.get(`/marketplace`, data).pipe(
      tap((content) => {
        this.onMarketplaceHomeChanged.next(content);
      }),
    );
  }

  getAll(
    field = 'slug',
    query = '',
    page = 0,
    limit = 24,
    sort = 'createdAt:desc',
  ): Observable<any> {
    this.locale = this.localeService.getActiveLocale();
    const data: any = {
      locale: this.locale.code,
      populate: '*',
      'pagination[page]': page,
      'pagination[pageSize]': limit,
      sort: sort,
    };

    if (query) {
      data[`filters[product_category][${field}][$contains]`] = query;
    }

    return this.api.get(`/products`, data).pipe(
      tap((footer) => {
        this.onProductsChanged.next(footer);
      }),
    );
  }

  getProductsForWidget(
    query: any = {},
    page = 0,
    limit = 24,
    sort = 'createdAt:desc',
  ): Observable<any> {
    this.locale = this.localeService.getActiveLocale();
    const data: any = {
      locale: this.locale.code,
      populate: '*',
      'pagination[page]': page,
      'pagination[pageSize]': limit,
      sort: sort,
      filters: {},
    };

    if (query?.numberOfProducts) {
      data['pagination[pageSize]'] = query.numberOfProducts;
    }

    if (query?.productType) {
      data.filters.productType =
        query.productType === 'cars' ? 'car' : query.productType;
    }

    if (query.product_categories) {
      data.filters = {
        ...data.filters,
        $or: query.product_categories?.data?.map((category: any) => {
          return {
            product_category: {
              slug: {
                $contains: category.attributes.slug,
              },
            },
          };
        }),
      };
    }

    const dataQuery = qs.stringify(data, {
      encodeValuesOnly: true,
    });

    return this.api.get(`/products`, '', '?' + dataQuery, false);
  }

  get(field = 'slug', query = ''): Observable<any> {
    this.locale = this.localeService.getActiveLocale();
    const data: any = {
      locale: this.locale.code,
      'populate[0]': '*',
      'populate[attributes][populate]': '*',
      'populate[city][populate]': '*',
      'populate[comments][populate]': '*',
      'populate[images][populate]': '*',
      'populate[product_brand][populate]': '*',
      'populate[product_category][populate]': '*',
      'populate[product_condition][populate]': '*',
      'populate[product_model][populate]': '*',
      'populate[saved_searches][populate]': '*',
      'populate[seller][populate]': '*',
    };

    // if (query) {
    //   data[`filters[${field}][$contains]`] = query;
    // }

    return this.api.get(`/products/${query}`, data).pipe(
      tap((footer) => {
        this.onProducfChanged.next(footer);
      }),
    );
  }

  getSolr(
    field = 'slug',
    query = '',
    page = 0,
    limit = 24,
    sort = 'published_at_dt desc',
  ): Observable<any> {
    const data: any = {
      q: '*:*',
      start: page * limit,
      rows: limit,
      sort: sort,
    };

    const dataQuery = qs.stringify(data, {
      encodeValuesOnly: true,
    });

    return this.api
      .getSolr<SolrResponse>(`/products`, '', '?' + dataQuery, false)
      .pipe(
        map((response: SolrResponse) => {
          const res: any = {
            facets: response.facet_counts,
            products: response.response.docs,
            numFound: response.response.numFound,
            numFoundExact: response.response.numFoundExact,
            start: response.response.start,
          };

          res.products = res.products.map((product) => {
            const p = Object.assign(new ProductSimple(), product);
            p.bootstrap();
            return p;
          });
          return res;
        }),
        tap((products) => {
          this.onProductsChanged.next(products);
        }),
      );
  }
}
