import { environment } from 'environments/environment';

export class ProductSimple {
  public images: Array<any> = [];

  constructor(
    public id: string = '',
    public name_s: string = '',
    public slug_s: string = '',
    public price_f: number = 0,
    public locale_s: string = '',
    public product_type_s: string = '',
    public product_brand_s: string = '',
    public product_model_s: string = '',
    public published_at_dt: string = '',
    public images_url_ss: Array<string> = [],
    public images_thumbnail_url_ss: Array<string> = [],
  ) {}

  bootstrap() {
    this.images = this.images_url_ss.map((url, index) => {
      return {
        id: index,
        url: this.imagePath(url),
        thumbnailUrl: this.imagePath(this.images_thumbnail_url_ss[index]),
        alt: this.name_s,
        title: this.name_s,
      };
    });
  }

  /*-----------------------------------------------------------------------------------
   * @ Public methods
   *-----------------------------------------------------------------------------------*/
  get link() {
    return `/marketplace/alterar-categoria-slug/${this.slug_s}-p${this.id}`;
  }

  /**
   *
   * @param url
   * @returns
   */
  imagePath(url: string) {
    return `${environment.assets}${url}` || '';
  }
}
